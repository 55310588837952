.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

#FOOTER {
	background-color: #1e1b4b;
	z-index: -1;
}

@media (min-width: 768px) {
	.leftSideImage {
		width: 50%;
	}
}

.productPic {
	width: 100%;
	height: auto;
	min-width: 250px;
}

.blueOne {
	background-color: #004a8e;
}

.blueTwo {
	background-color: #2880b5;
}

.blueThree {
	background-color: #4697c7;
}

.blueFour {
	background-color: #6ab0d9;
}

.blueFive {
	background-color: #9bcdeb;
}

.blueSix {
	background-color: #eaf9fd;
}

.redTextOne {
	color: #c20c09;
}

.blueTextOne {
	color: #004a8e;
}

.blueTextTwo {
	color: #2880b5;
}

.iceWater {
	background-image: url("../src/images/landingPage/IceWater.png");
	background-size: cover; /* Make the image as large as possible contained within the element */
	background-repeat: no-repeat; /* Do not repeat the image */
	background-position: center; /* Center the background image */
}

.hideScroll ::-webkit-scrollbar {
	display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.hideScroll {
	-ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
	scrollbar-width: none; /* Hide scrollbar for Firefox */
}
